import Interceptors from './interceptor'
import {Request, NormalResponse, Response, Method, Option} from '../model/api'


class HttpService<TRequest extends Request, TResponse extends Response> {
    
    private axios: any 
    private openID: Promise <any> | string | undefined

    constructor() {
        this.axios = new Interceptors().getInterceptors()
    }

    // http请求函数
    private async request (method: any, url: string, params?: TRequest, needOpenID?: true|false, options: Option={loading: true, text: '数据获取中...', error: false}) {
        
        return new Promise <TResponse>((resolve, reject) => {
            this.axios({
                url: url,
                method: method,
                data: params,
             }).then((res: any) => {
                resolve(res)
             }).catch((err: any) => {
                 console.error(url)
                 console.error(err.message)
                 reject(err)
             }).finally(() => {
                 console.log('close')
             })
         })
    }

    // http get请求
    public get (url: string, params?: any, needOpenID=true,  options: Option={loading: true, text: '数据获取中...', error: false}) {
        return this.request('get', url, params, needOpenID, options )
    }
    // http post请求
    public post (url: string, data?: any,  needOpenID=true, options: Option={loading: true, text: '数据获取中...', error: false}) {
        return this.request('post', url, data, needOpenID, options)
    }

     // http put请求
     public put (url: string, data?: any,  needOpenID=true, options: Option={loading: true, text: '数据获取中...', error: false}) {
        return this.request('put', url, data, needOpenID, options)
    }
}

export default new HttpService<{}, NormalResponse>()