import { NormalResponse } from "../model";
import request from "./request";

const post = request.post.bind(request)
const put = request.put.bind(request)
const get = request.get.bind(request)

export function isValid (res: NormalResponse) {
    return res && res.code == 0
}

export function menuData() {
    return request.post('/api/authority/findAllMenus.htm')
}

// 获取菜单树
export function getMenuTree() {
    return post(`/api/sysmenu/getMenuTree`)
}

// 根据菜单ID查询菜单
export function getMenuItem(id: string) {
    return post(`/api/sysmenu/get`, {menuId: id})
}


// 用户登陆
export function login(data: any) {
    return request.post('api/sysuser/login', data)
}

// 注销登录
export function loginout() {
    return post(`/api/sysuser/logout`)
}

// 获取用户信息
export function getUserInfo () {
    return request.post('/api/sysuser/queryOwnInfo')
}

// 当前用户菜单
export function userMenus() {
    return request.post(`/api/sysuser/getMyMenus`)
}

// 菜单
// 查询菜单列表
export function menuTree() {
    return request.post('/api/sysmenu/getAll')
}

// 添加菜单
export function addMenu(data: any) {
    return request.post('/api/sysmenu/add', data)
}

// 编辑菜单
export function editMenu(data: any) {
    return request.post('/api/sysmenu/update', data)
}

// 删除菜单
export function deleteMenu(data: any) {
    return request.post('/api/sysmenu/delete', data)
}

// 角色
// 添加角色
export function addRole(data: any) {
    return request.post('/api/sysrole/add', data)
}

// 删除角色
export function deleteRole(roldId: number) {
    return request.post('/api/sysrole/delete', {roleId: roldId})
}

// 获取角色列表
export function roleList(data: any) {
    return request.post('/api/sysrole/getPage', data)
}

// 编辑角色
export function editRole(data: any) {
    return request.post('/api/sysrole/update', data)
} 

// 获取角色详情
export function roleDetail(roleId: number | string) {
    return request.post('/api/sysrole/get', {roleId: roleId})
}


// 获取系统用户列表
export function userList(data: any) {
    return request.post('/api/sysuser/getPage', data)
}

// 添加用户
export function addUser(data: any) {
    return request.post('/api/sysuser/add', data)
}

// 编辑用户
export function editUser(data: any) {
    return request.post('/api/sysuser/update', data)
}

// 重置密码
export function initPassWord(data: any) {
    return request.post('/api/sysuser/pwd/reset', data)
}

// 获取用户详情
export function userDetail(userId: number) {
    return request.post('/api/sysuser/get', {userId: userId})
}



// 设备出库配送
// 创建出库单
// export function createSupplyOrder(data: any) {
//     return request.post('/api/device/createSupplyOrder.htm', data)
// }

// 出库单列表
export function supplyOrderList(data: any) {
    return request.post('/api/device/stock/order/out/getPage', data)
}

// 出库单详情
export function supplyOrderDetail(id: string) {
    return request.post('/api/device/stock/order/get', {orderId: id})
}

// 票出入库
// 票出库单列表
export function ticketOutStorageList(data: any) {
    return post(`/api/ticket/stock/order/out/getPage`, data)
}

// 票入库单列表
export function ticketInStorageList(data: any) {
    return post(`/api/ticket/stock/order/in/getPage`, data)
}

// 票出入库详情
export function ticketInOutStorageDetail(orderId: string) {
    return post(`/api/ticket/stock/order/get`, {orderId: orderId})
}

// 出库单修改
// export function supplyOrderModify(data: any) {
//     return request.post('/api/device/modifySupplyOrder.htm', data)
// }

// 取消出库单
// export function supplyOrderCancel(data: any) {
//     return request.post('/api/device/cancelSupplyOrder.htm', data)
// }



// 设备管理
// 创建设备出入库单
// export function createMachineInOutBill(data: any) {
//     return request.post('/api/device/stockIn.htm', data)
// }

// 设备入库单列表
export function machineInBillList(data: any) {
    return request.post('/api/device/stock/order/in/getPage', data)
}

// 设备出库单列表
export function machineOutBillList(data: any) {
    return request.post(`/api/device/stock/order/out/getPage`, data)
}

// 设备出入库单详情
export function machineBillDetail(data: any) {
    return request.post('/api/device/stock/order/get', data)
}

// 设备入库单明细详情
export function machineBillItemDetail(data: any) {
    return request.post(`/api/device/stock/queryStockOrderItem.htm`, data)
}

// 设备出入库单-明细分页
export function machineInOutDetailList(data: any) {
    return request.post('/api/device/stock/findStockOrderItemPage.htm', data)
}

// 设备出入库单编辑
export function machineInOutBillEdit(data: any) {
    return request.post('/api/device/stock/modify.htm', data)
}

// 添加设备到入库单
export function addMachinToBill(data: any) {
    return request.post('/api/device/stock/stockIn.htm', data)
}

// 设备发货
export function machineShipments(data: any) {
    return request.post('/api/device/stock/stockOut.htm', data)
}

// 完成出入库单
export function inOutBillFinish(data: any) {
    return request.post('/api/device/stock/finishStockOrder.htm', data)
}

// 取消出入库单
export function inOutBillCancel(data: any) {
    return request.post('/api/device/stock/cancelStockOrder.htm', data)
}

// 设备入库
export function machineStockIn (data: any) {
    return request.post('/api/device/stock/stockIn.htm', data)
}

// 取消入库
export function cancelMachineStockIn (data: any) {
    return request.post('/api/device/stock/stockInCancel.htm', data)
}

// 设备出库
export function machineStockOut(data: any) {
    return request.post('/api/device/stock/stockOut.htm', data)
}

// 取消出库
export function cancelMachineStockOut(data: any) {
    return request.post('/api/device/stock/stockCancleOut.htm', data)
}

// 店铺列表
// export function storeList (data: any) {
//     return request.post('/api/shop/findPage.htm', data)
// }

// 店铺详情
// export function storeDetail(data: any) {
//     return request.post('/api/shop/detail.htm', data)
// }

// 店铺审核通过
export function storeApprove(data: any) {
    return request.post('/api/shop/reviewAccept.htm', data)
}

// 店铺审核拒绝
export function storeAuditRefuse(data: any) {
    return request.post('/api/shop/reviewReject.htm', data)
}

// 店主列表
export function storeKeeperList(data: any) {
    return request.post('/api/shopuser/findPage.htm', data)
} 

// 店主详情
export function storeKeeperDetail(data: any) {
    return request.post('/api/shopuser/detail.htm', data)
}

// 快递物流列表
export function expressCompanyList(data: any) {
    return request.post('/api/expresscompany/findPage.htm', data)
}

// 添加快递公司
export function addExpressCompany(data: any) {
    return request.post('/api/expresscompany/add.htm', data)
}

// 修改信息
export function expressCompanyModify(data: any) {
    return request.post('/api/expresscompany/modify.htm', data)
}

// 代理商列表
export function agencyList(data: any) {
    return request.post('/api/agency/findPage.htm', data)
}

// 代理商详情
export function agencyDetail(data: any) {
    return request.post('/api/agency/detail.htm', data)
}

// 代理商添加
export function agencyAdd(data: any) {
    return request.post('/api/agency/add.htm', data)
}

// 代理商修改
export function agencyModify(data: any) {
    return request.post('/api/agency/modify.htm', data)
}

// 设置佣金比例
export function setCommissionRate(data: any) {
    return request.post('/api/agency/setCommissionRate.htm', data)
}

// 设备列表
export function machineList(data: any) {
    return request.post('/api/device/getPage', data)
}

// 设备详情
export function machineDetail(data: any) {
    return request.post('/api/device/get', data)
}

// 设备-信息修改
// export function machineModify(data: any) {
//     return request.post('/api/device/modify.htm', data)
// }

// 设备-上架
// export function machineAllocate(data: any) {
//     return request.post('/api/device/allocate.htm', data)
// }

// 分配运营人员
export function assginOperator(data: any) {
    return request.post(`/api/device/allocateOperator.htm`, data)
}

// 设备-设置物联网三元组
export function modifyIotInfo(data: any) {
    return request.post(`/api/device/setParameterIot`, data)
}

// 设备-升级固件
export function upgradeFirmware(no: string) {
    return post(`/api/device/updTheFirmware`, {deviceNo: no})
}

// 设备-解绑
export function machineUnbind(no: string) {
    return post(`/api/device/unbind`, {deviceNo: no})
}

// 设备-设置票类型
export function machinSetTicketType(data: any) {
    return post(`/api/device/setParameterTicketType`, data)
}

// 设备-上报版本号
export function copyRight(deviceNo: string) {
    return post(`/api/device/getDeviceVersion`, {deviceNo: deviceNo})
}

// 设备-设置LED灯
export function setLED(data: any) {
    return post(`/api/device/setDeviceLedStatus`, data)
}

// 设备-设置声音
export function setSound(data: any) {
    return post(`/api/device/setDeviceVolumeLevel`, data)
}

// 设备-设置票面金额
export function machineSetUnitAmount(data: any) {
    return post(`/api/device/setParameterTicketUnitAmount`, data)
}

// 设备-设置票总额度
export function machineSetTicketAmount(data: any) {
    return post(`/api/device/setParameterTicketTotal`, data)
}

// 设备-设置补票触发额度
export function machineSetReplacementLimit(data: any) {
    return post(`/api/device/setParameterAutoTriggerFillThresholdNumber`, data)
}

// 设备-配置新大陆支付参数
export function modifyPaymentParm(data: any) {
    return request.post(`/api/device/setParameterPayment`, data)
}

// 设备-检查新大陆支付参数
export function checkPaymentParme(data: any) {
    return post(`/api/device/checkAndSetParameterDone`, data)
}

// 设备-设置数据库卡卡号
export function machineSetDataCardNo(data: any) {
    return post(`/api/device/setDataCardNo`, data)
}

// 设备-冲正
export function machineTicketRevise(data: any) {
    return post(`/api/device/rectification`, data)
}

// 设备-设置代理商id
export function machineSetAgentId(data: any) {
    return post(`/api/device/setAgentId`, data)
}

// 设备-设置补票人员id
export function machineSetReplacementerId(data: any) {
    return post(`/api/device/setOperatorId`, data)
}

// 设备-设置店铺id
export function machineSetStoreId(data: any) {
    return post(`/api/device/setShopId`, data)
}

// 设备-设置设备押金
export function machineSetPledge(data: any) {
    return post(`/api/device/setDeviceDeposit`, data)
}

// 设备-强制恢复到出票状态
export function machineRefreshDrawBillStatus(data: any) {
    return post(`/api/device/resetWork`, data)
}

// 设备-设置自动补票额度
export function autoFillNum(data: any) {
    return request.post(`/api/device/setAutoTriggerFillThreshold.htm`, data)
}

// 设备-设置票额度
export function setTicketAmount(data: any) {
    return request.post('/api/device/setTicketAmount.htm', data)
}

// 设备-设置小包票张数
export function setUnitTicketNumber(data: any) {
    return request.post(`/api/device/setTicketFillUnitPackNumber`, data)
}

// 设备-通知代理端绑定代理ID
export function informAgentBindAgent(data: any) {
    return request.post(`/api/device/invokeBindAgent`, data)
}

// 设备-恢复出票
export function resetWork(data: any) {
    return request.post('/api/device/resetWork.htm', data)
}

// 设备批量上架
export function betchAllocate(data: any) {
    return request.post(`/api/device/batchAllocate.htm`, data)
}

// 设置店铺佣金比例
export function setStoreCommissionRate(data: any) {
    return request.post('/api/device/setShopCommissionRate.htm', data)
}

// 设置设备押金
export function setMachineCashPledge(data: any) {
    return request.post('/api/device/setDeviceDeposit.htm', data)
}

// 票押金—加
export function ticketCashPledgePlus(data: any) {
    return request.post('/api/device/addTicketDeposit.htm', data)
}

// 票押金-减
export function ticketCashPledgeMinus(data: any) {
    return request.post('/api/device/subtractTicketDeposit.htm', data)
}

// 补票列表
export function replenishList(data: any) {
    return request.post(`/api/ticket/supply/order/findPage.htm`, data)
}

// 补票详情
export function replenishDetail(data: any) {
    return request.post(`/api/ticket/supply/order/detail.htm`, data)
}

// 发货
export function deliver(data: any) {
    return request.post(`/api/ticket/supply/order/deliver.htm`, data)
}

// 发货信息修改
export function deliverModify(data: any) {
    return request.post('/api/ticket/supply/order/modify.htm', data)
}

// 补票取消
export function cancelReplenish(data: any) {
    return request.post('/api/ticket/supply/order/cancel.htm', data)
}

// 运营人员分页查询
export function operatorList(data: any) {
    return request.post(`/api/device/operator/getPage`, data)
}

// 运营人员详情
export function orperatorDetail(id: any) {
    return request.post(`/api/device/operator/get`, {id: id})
} 

// 运营人员修改
export function opertorModify (data: any) {
    return request.post(`/api/device/operator/update`, data)
}



// 出票列表
export function drawBillList(data: any) {
    return request.post(`/api/ticket/sell/order/getPage`, data)
}

// 出票详情
export function drawBillDetail(id: any) {
    return request.post(`/api/ticket/sell/order/get`, {orderId: id})
} 

// 兑奖列表
export function redeemList(data: any) {
    return request.post(`/api/ticket/prize/order/getPage`, data)
}

// 兑奖详情
export function redeemDetail(id: string) {
    return request.post(`/api/ticket/prize/order/getPage`, {orderId: id})
}

// 手动兑奖
export function redeemManual(data: any) {
    return request.post('/api/ticket/prize/order/manualReward', data)
}

// 兑奖码兑奖
export function redeemByCode(data: any) {
    return request.post(`/api/ticket/prize/order/createManualReward`, data)
}

// 加奖
export function raisePriceManual(data: any) {
    return request.post(`/api/ticket/prize/order/secondManualReward`, data)
}

// 人工出票
export function drawBillManual(id: any) {
    return request.post(`/api/ticket/sell/order/restartWorking.htm?id=${id}`)
}

// 获得省市区
export function getArea(code = 0) {
    return request.post(`/api1/area/findChilds.htm?parentCode=${code}`)
}


// 设备配送
// 设备配送-列表
export function machineDeliverList(data: any) {
    return request.post(`/api/device/supply/order/getPage`, data)
}

// 设备配送-详情
export function machineDeliverDetail(id: string) {
    return request.post(`/api/device/supply/order/get`, {orderId: id})
}

// 设备配送-设置是否马上生成订单状态
export function machineDeliverSetNeedTicket(data: any) {
    return request.post(`/api/device/supply/order/setNeedTicket`, data)
}

// 设备配送单-分配配送员
export function machineDeliverSetDeliverer(data: any) {
    return request.post(`/api/device/supply/order/setOperator`, data)
}

// 票配送
// 票配送-列表
export function ticketDeliverList(data: any) {
    return request.post(`/api/ticket/supply/order/getPage`, data)
}

// 票配送-详情
export function ticketDeliverDetail(id: string) {
    return request.post(`/api/ticket/supply/order/get`, {orderId: id})
}

// 票配送-分配配送员
export function ticketDeliverAssignDeliverer(data: any) {
    return request.post(`/api/ticket/supply/order/setOperator`, data)
}

// 票配送-刷新生成配送单
export function ticketRefreshNewTickets() {
    return request.post(`/api/ticket/supply/order/manualGenerate`)
}

// 彩民管理
// 彩民-列表
export function consumerList(data: any) {
    return request.post(`/api/consumer/getPage`, data)
}

// 彩民-详情
export function consumerDetail(consumerId: string) {
    return request.post(`/api/consumer/get`, {consumerId: consumerId})
}

// 彩民-详情-资金明细
export function consumerDetailFund(data: any) {
    return request.post(`/api/consumer/wallet/log/getPage`, data)
}

// 彩民-提现-列表
export function consumerWithdrawList(data: any) {
    return post(`/api/consumer/withdraw/order/getPage`, data)
}

// 彩民-提现-详情
export function consumerWithrawDetail(orderId: string) {
    return post(`/api/consumer/withdraw/order/get`, {orderId: orderId})
}

// 彩民-提现-开始处理
export function consumerWithdrawStart(orderId: string) {
    return post(`/api/consumer/withdraw/order/handleStart`, {orderId: orderId})
}

// 彩民-提现-处理成功
export function consumerWithdrawSuccessed(orderId: string) {
    return post(`/api/consumer/withdraw/order/handleSuccess`, {orderId: orderId})
}

// 彩民-提现-取消
export function consumerWithdrawCancel(data: any) {
    return post(`/api/consumer/withdraw/order/cancel`, data)
}

// 彩民-公告列表
export function noticeList(data: any) {
    return post(`/api/prizeNotice/getList`, data)
}

// 彩民-新增公告信息
export function addNoticeInfo(data: any) {
    return post(`/api/prizeNotice/addPrizeNotice`, data)
}

// 彩民-公告详情
export function noticeInfoDetail(id: string) {
    return post(`/api/prizeNotice/getInfo`, {id: id})
}

// 彩民-公告详情更改
export function noticeInfoModify(data: any) {
    return post(`/api/prizeNotice/updPrizeNotice`, data)
}

// 菜名

// 沈少接口
// 代理申请列表
export function agentApplyList(data: any) {
    return post(`/api/agent/applyAgentList`, data)
}

// 代理详情
export function agentApplyDetail(id: string) {
    return post(`/api/agent/applyAgentInfo`, {id: id})
}

// 代理审核
export function agentCheck(data: any) {
    return post(`/api/agent/applyAgentApprove`, data)
}

// 代理列表
export function agentList(data: any) {
    return post(`/api/agent/list`, data)
}

// 代理商详情
export function agentDetail(id: string) {
    return post(`/api/agent/info`, {agentId: id})
}

// 店铺列表
export function storeList(data: any) {
    return post(`/api/shop/list`, data)
}

// 店铺详情
export function storeDetail(shopId: string) {
    return post(`/api/shop/info`, {shopId: shopId})
}

// 店铺信息审核
export function storeInfoCheck(data: any) {
    return post(`/api/shop/approve`, data)
}

// 店铺信息修改
export function storeInfoModify(data: any) {
    return post(`/api/shop/update`, data)
}

// 采购订单列表
export function POList(data: any) {
    return post(`/api/order/getOrderList`, data)
}

// 采购订单详情
export function PODetail(orderId: string) {
    return post(`/api/order/getOrderInfo`, {orderId: orderId})
}

// 采购-发货
export function deliverGoodsEvent(data: any) {
    return post(`/api/order/deliverGoods`, data)
}

// 代理提现列表
export function agentWithdrawList(data: any) {
    return post(`/api/withdraw/list`, data)
}

// 代理提现详情
export function agentWithdrawDetail(logId: string) {
    return post(`/api/withdraw/info`, {logId: logId})
}

// 代理提现审核
export function agentWithdrawCheck(data: any) {
    return post(`/api/withdraw/approve`, data)
}

// 导入代理列表
export function importAgentList(data: any) {
    return post(`/api/agent/getInternalAgentList`, data)
}

// 导入一代详情
export  function importAgentDetail(id: string) {
    return post(`/api/agent/getInternalAgentInfo`, {id: id})
}

// 创建代理
export function importAgentCreate(data: any) {
    return post(`/api/agent/addInternalAgent`, data)
}

// 导入代理-代理结算类型列表
export function agentSettlerTypes() {
    return get(`/api/agent/getAgentSettlerType`)
}

// 代理修改
export function importAgentModify(data: any) {
    return post(`/api/agent/updInternalAgent`, data)
}

// 代理佣金
export function importAgentRate() {
    return post(`/api/agent/getDefaultPolicy`)
}

// 代理佣金设置
export function importAgentSetRate(data: any) {
    return post(`/api/agent/setAgentPolicy`, data)
}

// 批量设置参数
export function setPrams(data: any) {
    return post(`/api/device/batchSetParameter`, data)
}

// 设备替换列表
export function machineChangeList(data: any) {
    return post(`/api/deviceChange/list`, data)
}

// 设备替换详情
export function machineChangeDetail(id: string) {
    return post(`/api/deviceChange/info`, {id: id})
}

// 替换记录修改
export function machineChangeModify(data: any) {
    return post(`/api/deviceChange/update`, data)
}

// 新增替换
export function machineChangeAdd(data: any) {
    return post(`/api/deviceChange/add`, data)
}

// 新增退换
export function deviceChangeAdd(data: any) {
    return post(`/api/deviceChange/add`, data)
}

// 报表统计
export function getStatement(type: number) {
    return post(`/api/report/reportTotal`, {type: type})
}

// 监测
// 提现账户余额
export function withdrawBalance() {
    return post(`/api/account/getPayAccountBalance`)
}


// 创建自营店
export function createPlatformShop(data: any) {
    return post(`/api/deviceCabin/createPlatformShop`, data)
}

// 小屋列表
export function deviceCabinList(cabinNo: any) {
    return post(`/api/deviceCabin/list`, cabinNo)
}

// 新增小屋
export function createCabin(data: any) {
    return post(`/api/deviceCabin/createCabin`, data)
}

// 小屋详情
export function cabinDeviceList(id: string) {
    return post(`/api/deviceCabin/info`, {id: id})
} 

// 新增小屋机器
export function addCabinDevice(data: any) {
    return post(`/api/deviceCabin/device-cabin-info`, data)
}

// 下架小屋机器
export function offDevice(id: string) {
    return put(`/api/deviceCabin/device-cabin-info/end`, {id})
}

// 上架小屋机器
export function onDevice(id: string) {
    return put(`/api/deviceCabin/device-cabin-info/start`, {id})
}

