import { createStore, GetterTree } from 'vuex'
import roleInfo from './modules/author'
import {RuleGeter, RootState} from '../utils/model'

const getters: GetterTree<RuleGeter, RootState> = {
  roles: roleInfo => {
    return roleInfo.state
  },
  menuTree: roleInfo => {
    return roleInfo.state
  }
}

export default createStore({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  getters,
  modules: {
    roleInfo
  }
})
