import { createRouter, createWebHistory } from 'vue-router'
import {constRoute, routes} from './route'
import store from '../store'
import { MENUTREE, MENU } from '@/utils/model'
import { isValid, userMenus } from '../utils/api'

const whiteList = [
  '/login',
  '/menu',
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constRoute
})

function geneRoles(data: any) {
  const permission: number[] = []
  data.forEach((ele: any) => {
    permission.push(ele.menuId)
  });
  return permission
}

function hasPermission (role: any, roles: any) {
  return role == 1 || roles.indexOf(role) > -1
}

function geneRouts(data: any, roles: any) {
  const _data = data.filter((ele: MENUTREE, index: number) => {
      if(hasPermission(ele.meta && ele.meta.menuId, roles)) {
        if(ele.children && ele.children.length > 0) {
          ele.children = geneRouts(ele.children, roles)
        }
        return true
      }
  })
  return _data
}

async function loadData(next: any, to: any) {
  try {
    const result = await userMenus()
    if(isValid(result)) {
      const _roles = geneRoles(result.data.myMenu)
      const menuTree = geneRouts(routes, _roles)
      store.commit('roleInfo/setRoles',  _roles)
      store.commit('roleInfo/setMenuTree', menuTree)
      console.log(store.getters['roleInfo/roles'])
      menuTree.forEach((ele: any) => {
        router.addRoute(ele)
      })
      next({...to, replace: true})
    }
  } catch(e) {
    console.error(e)
  }
}


router.beforeEach(async (to, from, next) => {
  if(store.getters['roleInfo/roles'] < 1 && whiteList.indexOf(to.path) < 0) {
    await loadData(next, to)
    // next()
  } else {
    next()
  }
})

export default router
