import { Module, GetterTree, MutationTree} from 'vuex'
import { AuthorInfo,  RootState} from '../../utils/model'
const state: AuthorInfo = {
    roles: [],
    menuTree: []
}

const getters: GetterTree<AuthorInfo, RootState> = {
    roles: state => {
        return state.roles
    },
    menuTree: state => {
        return state.menuTree
    }
}

const mutations: MutationTree<AuthorInfo> = {
    setRoles (state: AuthorInfo, values) {
        state.roles = values
    },
    setMenuTree (state: AuthorInfo, value) {
        state.menuTree = value
    }
}

const storeInfo: Module<AuthorInfo, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations
}

export default storeInfo