// import moment from 'moment'
import dayjs from 'dayjs'
import router from '../../router'
import {message, Modal} from 'ant-design-vue'
import {RouteData, MENU} from '../model'
import md5 from 'js-md5'

export function decodePassword(value: any) {
    return md5(value)
}

export function redirctTo(routeData: RouteData) {
    router.replace(routeData)
}

export function geneIndex(data: any, pageSize: number, currentPage: number) {
    if (data && data.length) {
        data.forEach((ele: any, index: number) => {
            ele.index = (currentPage-1)*pageSize + index + 1
        })
        return data
    } else {
        return []
    }
}

export async function confirmDialog(title: string, content: string) {
    try {
        return new Promise((resolve) => {
            Modal.confirm({
                title: title,
                content: content,
                icon: '',
                onOk() {
                    resolve(true)
                }
            })
        })
    } catch(e) {
        console.error(e)
    }
}


export function formatTime (time: any,type = 'YYYY-MM-DD HH:mm') {
    if(time) {
        return dayjs(time).format(type)
    }
    return undefined
}

export function showMessage(str: string, type = 'success') {
    switch(type) {
        case 'success':
            message.success(str)
            break
        case 'err':
            message.error(str)
            break
        case 'warn':
            message.warning(str)
            break
        default:
            message.warning(str)
            break
    }
}

export function geneUniId(data: any) {
    if(data && data[0] && data[0].key) {
        return data
    } else {
        data.forEach((ele: any, index: number) => {
            ele.key = index + new Date().getTime()
        })
        return data
    }
}

 // 格式化菜单树 
 export function formatMenuTree(tree: any, keyName = 'menuId') {
    tree.forEach((ele: any) => {
        ele.key = ele[keyName]
        ele.title = ele.menuName || '主菜单'
        if (ele.childMenus) {
            formatMenuTree(ele.childMenus)
        }
    }) 
    return tree
}

// 

// 给数组对象赋值
export function assginDataToArray(data: any, array: any) {
    array.forEach((ele: any) => {
        ele.value = ''
        if(ele.prop && data[ele.prop] != void 0) {
            ele.value = data[ele.prop]
        } else if(ele.props) {
            let arr = ''
            ele.props.forEach((propsEle: any) => {
                arr += data[propsEle] || ''
            })
            ele.value = arr
        }
    })
    return array
}


// 获取数组对象里的特定对象
export function findArrObjItem(key: string, value: any, arr: []) {
    const item = arr.filter(ele => ele[key] == value)
    console.log(item) 
    return item&&item[0] || {}
}

// 获取对象里的值
export function getValueFromObjArr(arr: [], key: any) {
   const obj = new Map()
   arr.forEach((ele) => {
        obj.set(ele[0], ele[1])
    })
    return obj.get(key) || ''
}

  // 添加分位
  export function appendCent(num = '0') {
    //   let _num = num
      const _num = num.toString().split('.')
      return ! _num[1] ? `${_num}.00` 
             : _num[1].length == 1 ? `${_num[0]}.${_num[1]}0` 
             : _num[1].length == 2 ?  num
             : `${_num}.00`
  }

  export function isEmpty(items: any, labelName = 'value') {
    for (let i = 0; i < items?.length; i++) {
        if (items[i]?.[labelName] == '') {
            showMessage(`${items[i].label}不能为空`)
            return true
        }
    }
    return false
  }