<template>
    <div class='dialog_content'>
        <div v-if="readOnly">
            <div v-for="(item, itemIndex) in formItems" :key="item.name">
                <h3 v-if="item.type == 'title'">
                    {{item.value}}
                </h3>
                <div v-if="item.type == 'img'">
                    <p class="dialog_content_text-dark">{{item.label}}</p>
                </div>
                <p v-if="item.type == 'input' || 'select'">
                    <label>{{item.label}}: </label>
                    <text class="dialog_content_text-dark">{{item.value}}</text>
                    <a-button size="small" @click="handleEvent(item.eventName, itemIndex)" v-if="item.eventName">{{ item.eventLabel }}</a-button>
                </p>
                <label v-if="item.type == 'radio'">{{item.label}}: </label>
                <a-radio-group v-model:value="item.value" v-if="item.type == 'radio'">
                         <a-radio-button v-for="(radio) in item.options" :value="radio.value" :key="radio.value">
                             {{radio.label}}
                         </a-radio-button>
                </a-radio-group>
            </div>
            <slot></slot>
        </div>
        <a-form 
            v-else
            ref="ruleForm" 
            layout="horizontal"
            :rules="rules" 
            v-bind="formItemLayout"
            :model="forms">
            <a-form-item 
                v-for="(item, itemIndex) in formItems" 
                :key="item" 
                :name="item.name" 
                :label="item.label" 
                 v-show="!item.hidden">
                    <!-- input -->
                    <p class="input_box" v-if="item.type == 'input'" >
                        <a-input 
                            :name="item.name"
                            :placeholder="'请输入'+ item.label"
                            v-model:value="item.value"/>
                            <text @click="handleEvent(item.eventName, itemIndex)" v-if="item.eventName">{{ item.eventLabel }}</text>
                    </p>
                     <!-- radia -->
                     <a-radio-group v-model:value="item.value" v-if="item.type == 'radio'">
                         <a-radio-button v-for="(radio) in item.options" :value="radio.value" :key="radio.value">
                             {{radio.label}}
                         </a-radio-button>
                     </a-radio-group>
                     <!-- selector -->
                     <a-select 
                        v-if="item.type=='selector'"
                        :placeholder="'请选择'+item.label"
                        style="width: 120px"
                        v-model:value="item.value"
                        allowClear>
                        <a-select-option 
                            v-for="option in item.options"
                            :key="option"
                            :value="item.key ? option[item.key] : option">{{option.labelName ? option.labelName : option}}</a-select-option>
                    </a-select>
                    <!-- 时间 -->
                    <a-config-provider v-if="item.type == 'timeSelector'" :locale="locale">
                        <a-date-picker placeholder="请选择时间" v-model:value="item.value" @change="onChange" />
                    </a-config-provider>
                    <!-- 省市区 -->
            </a-form-item>
            <slot></slot>
        </a-form>
    </div>
</template>
<script>
import { reactive, ref, toRefs} from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
    name: '',
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        formItems: {
            type: Array,
            default: () => {
                return [
                    {label: '角色名称', value: '', type: 'input', name: 'trueName'},
                    {label: '描述', value: '', type: 'input', name: 'desc'}
                ]
            }
        },
         rules: {
            type: Object,
            default: () => {
                return {
                    trueName: [
                        { required: true, message: '请输入姓名', trigger: 'blur'}
                    ]
                }
            }
        },
        forms: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    desc: ''
                }
            }
        }
    },
    setup(prop, context) {
        const state= reactive({
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                locale: zhCN
            }
        })
        const ruleForm = ref()
        function handleEvent(eventName, eventValue) {
            context.emit(eventName, eventValue)
        }
        return {
            ...toRefs(state),
            ruleForm,
            handleEvent
        }
    },
}
</script>
<style>
.ant-modal-body {
        max-height: 600px;
        overflow-y: auto;
}
.ant-radio-button-wrapper {
    margin: 0 5px 5px 0;
}
</style>
<style lang='scss' scoped>
.input_box {
    display: flex;
    word-break: keep-all;
    text {
        display: inline-block;
        padding-left: 5px;
    }
}

.dialog_content {
    &_item {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        align-items: center;
        &_label {
            width: 80px;
            text-align: right;
            padding-right: 8px;
        }
    }
    &_text-dark {
        color: #000;
        margin-right: 8px;
    }
    h3 {
        font-weight: 700;
        color: #1890ff;
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}
</style>