// import { createApp } from 'vue'
// import App from './App.vue'
import router from './router'
import store from './store'

// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'

import Table from './components/Table.vue'
import From from './components/Form.vue'
import Dialog from './components/Dialog.vue'

import { app } from '../src/components/lazy_antd'
import './style/index.scss'
// import './utils/permission'

// const app = createApp(App)
app.component('m-table', Table)
app.component('m-form', From)
app.component('m-dialog-content', Dialog)
app.use(store).use(router).mount('#app')
