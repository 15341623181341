<template>
    <div class="form">
        <slot></slot>
        <template v-for="(item) in formItems" :key="item">
            <div class="form_item" v-if="item.type == 'input'">
                <label>{{item.label}}</label>
                <a-input :allowClear="true" v-model:value="item.value" :placeholder="'请输入'+item.label"></a-input>
            </div>
            <div class="form_item" v-if="item.type=='selector'">
                <label>{{item.label}}</label>
                <a-select 
                    :placeholder="'请选择'+item.label"
                    style="width: 120px"
                    v-model:value="item.value"
                    allowClear
                    >
                     <a-select-option 
                        v-for="option in item.options"
                        :key="option"
                        :value="item.key ? option[item.key] : option">{{item.key ? option.label : option}}</a-select-option>
                </a-select>
                <a-button 
                    v-if="item.button"
                    @click="emitEvent(item.button.eventName, item.value)"
                    >
                        {{item.button.label}}
                </a-button>
            </div>
            <div class="form_item margin_small" v-if="item.type === 'button'">
                <a-button @click="emitEvent(item.eventName)" type=primary>{{item.label}}</a-button>
            </div>
            <div class="form_item margin_small file_item" v-if="item.type=='file'">
                <a-input @change="fileChange" type="file" v-model:value="item.value"></a-input>
                <a-button type=primary>{{item.label}}</a-button>
            </div>
            <div class="form_item" v-if="item.type === 'timeSelector'">
                <label>{{item.label}}</label>
                <a-config-provider :locale="state.locale">
                    <a-date-picker placeholder="请选择时间" v-model:value="item.value" @change="onChange" />
                </a-config-provider>           
            </div>
        </template>
    </div>
</template>
<script>
import {reactive} from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
    props: {
        formItems: {
            type: Array,
            default:() => {
                return [
                    {type: 'input', label: '人员姓名', value: null},
                    {type: 'timeSelector', label: '请选择时间', value: null},
                    {type: 'selector', label: '设备ID',  options: [1, 3, 4, 5]},
                    {type: 'button', label: '按钮1', eventName: 'handle1'},
                    {type: 'button', label: '按钮2', eventName: 'handle2'},
                    {type: 'button', label: '按钮3', eventName: 'handle3'},
                ]
            }
        }
    },
    setup(props, context) {
        const state = reactive({
            value: null,
            locale: zhCN
        })
        function onChange(e) {
            console.log(e)
        }
        function emitEvent(name, value = '') {
            context.emit(name, value)
        }
        function fileChange(e) {
            const fileList = e.target.files;
            if (fileList.length > 0) {
                const file = fileList[0];
                const formData = new FormData();
                formData.set('file', file)
                context.emit('fileevent', formData)
                e.target.value = ''
                
            }
        }
        return {
            state,
            onChange,
            emitEvent,
            fileChange
        }
    }
}
</script>
<style lang="scss" scoped>
.form {
    text-align: left;
    margin-bottom: 30px;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    label {
        margin-right: 5px;
    }
    &_item {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 15px;
        margin-bottom: 20px;
    }
   .margin_small {
        margin-right: 5px;
        &:first-child {
            margin-right: 15px;
        }
    }
    .file_item {
        position: relative;
        input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            opacity: 0;
        }
    }
}
</style>