/**
 * 生成基础axios对象，并对请求和相应做处理
 * 前后端约定接口返回解构规范
 * {
 * code: 0,
 * data: {},
 * message: ''
 * }
 */

import axios from 'axios'
import {message} from 'ant-design-vue'
import router from '../../router'


 export default class Interceptors {

     private instance: any

     constructor() {
         // 创建axios实例
         this.instance = axios.create({
             baseURL: '',
             timeout: 1000 * 120
            })
         // 初始化拦截器
         this.initInterceptors()
     }

     
     private initInterceptors () {
         // 设置post请求头
         this.instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        //  this.instance.defaults.headers['openId'] = 'oI01f5Vsd7d5smLUKA4jQeXDEH-o'
        // let openID = this.instance.defaults.headers['openId']
         /**
          * 请求拦截器
          * 每次请求前，如果存在token则在请求头中携带token
          */
         this.instance.interceptors.request.use(
             (config: any) => {
                 const data = config.data
                 for(const i in data) {
                    data[i] = data[i] === '' ? undefined : data[i]
                 }
                 console.log(config)
                 return config
             },
             (err: any) => {
                 console.log(err)
             }
         )

         // 响应拦截器
         this.instance.interceptors.response.use(
             (response: any) => {
                const res = response
                const {code, message: msg} = res.data
                if (!code) { // 返回值正常
                    return res && res.data
                }
                switch(code) {
                  case 9002: 
                  case 9001:
                    router.replace('/login')
                    break
                  case -1:
                    message.error(msg)
                    break
                  case 1: 
                    message.error(msg)
                    break
                  default:
                    message.error(msg)
                }
                return res.data
             },
             (err: any) => {
                 const { response } = err 
                 if (response) {
                     this.errHandle(response)
                     return Promise.reject(response.data)
                 } else {
                     console.log(err)
                 }
             }
         )
     }
     /**
         * http握手错误
         * @param res 响应回调，根据不同响应进行不同操作
    */
    private errHandle (res: any) {
            switch(res.status) {
                case 401:
                    break;
                case 403:
                    break
                case 404:
                    message.warning('请求资源不存在')
                    break
                default:
                   message.error('连接接错误')
            }
   }

   // 为了让http.ts中获取初始化好的axios实例
   public getInterceptors() {
       return this.instance
   }
 }

